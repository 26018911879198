<template>
  <div id="app">
    <!-- <el-scrollbar height="699px" @scroll="scroll"> -->
     <router-view v-if="isRouterAlive"></router-view>
    <!-- </el-scrollbar> -->
  </div>
</template>
<script>
export default {
  provide(){
    return{
      reload:this.reload
    };
  },
  data(){
    return{
      isRouterAlive:true
    }
  },
  methods:{
    reload(){
      this.isRouterAlive = false;
      this.$nextTick(function(){
        this.isRouterAlive = true;
      })
    },
    // scroll(e){
    //   console.log(e)
    // }
  }
}
</script>
<style lang="scss">
html{
  height: 100%;
  overflow:auto;
}
body{
  height: 100%;
  margin: 0;
  font-size:1rem;
  overflow:auto;
  // font-family:"Mazzard M Medium"
}
#app {
  height: 100%;
}
img{
  max-width: 100%;
}
p{
  margin: 0 0 8px 0;
}
input{
  outline:none;
}
// .scrollbar-demo-item {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   height: 50px;
//   margin: 10px;
//   text-align: center;
//   border-radius: 4px;
//   background: #8f3ca0;
//   color: #8f3ca0;
// }
.el-scrollbar__bar.is-vertical{
    z-index:99;
    display:block !important;
}
.imginner .el-scrollbar__bar.is-vertical{
  background-color: #545b61 !important;
}
.el-scrollbar__thumb{
    // background-color: #8f3ca0 !important;
    background-color: #E81F76 !important;
    opacity: 1 !important;
}
</style>
